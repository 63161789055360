import {
    MysolutionInvalidTokenResponse,
    MysolutionVacancy,
    StoryblokDataSource,
    VacancyProps
} from "storyblok/components";
import { getStoryblokApi } from "@storyblok/react";
import memoryCache from "memory-cache";

export type OfferPromiseResult = {
    offers: Array<VacancyProps>;
};

type MysolutionAuthApiResult = {
    access_token: string;
};

export const apiEndpoints: Array<string> = [
    process.env.PHARMALEAD_OFFERS_API as string,
    process.env.LEADHC_OFFERS_API as string,
    process.env.MEDILEAD_OFFERS_API as string
];

// -------------------------------------
// Helper functions
// -------------------------------------

export async function getOffersFromEndpoint(
    endpoint: string
): Promise<OfferPromiseResult> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return await fetch(endpoint)
        .then(response => response.json())
        // eslint-disable-next-line
        .catch(err => console.error(err));
}

/**
 *
 * @param noCache
 * Boolean to indicate if the cached token should be skipped and a new one should be retrieved,
 *
 *
 * Default is to use the cache
 */
export async function getMysolutionToken(noCache?: boolean) {
    const cacheKey = "mysolution-token";
    const cachedData: MysolutionAuthApiResult | null =
        memoryCache.get(cacheKey);

    if (cachedData && !noCache) {
        return cachedData.access_token;
    }

    const api = process.env.MYSOLUTION_API;
    const clientId = process.env.MYSOLUTION_CLIENT_ID;
    const clientSecret = process.env.MYSOLUTION_CLIENT_SECRET;

    if (!api || !clientId || !clientSecret) {
        console.error("Secret(s) not present in .env while retrieving token");
        return null;
    }

    const mysolutionTokenEndpoint = `/oauth2/token?grant_type=client_credentials&client_id=${clientId}&client_secret=${clientSecret}`;

    try {
        const result: MysolutionAuthApiResult = await fetch(
            `${api}${mysolutionTokenEndpoint}`
        ).then(res => res.json());

        // Keep token in memory cache for 24 hours
        memoryCache.put(cacheKey, result, 1 * 1000 * 60 * 60 * 24);

        if (!result.access_token) {
            console.error(
                "No access token given while retrieving mysolution token",
                result
            );
            return null;
        }

        return result.access_token;
    } catch (error) {
        console.error("Error while retrieving mysolution token", error);
        return null;
    }
}

/** Some vacancies have "In eigen regio" or "Landelijk" instead of an actual place name
 * This function should replace those values with "Nederland"
 */
function replaceNonRegionalLocation(value?: string) {
    if (value === "In eigen regio" || value === "Landelijk") {
        return "Nederland";
    }

    return value;
}

function mapMysolutionVacanciesToVacancyProps(
    msVacancies: Array<MysolutionVacancy>
): Array<VacancyProps> {
    return msVacancies.map(vacancy => {
        const {
            msf__Job_Description__c,
            msf__Employment_Conditions__c,
            msf__Company_Description__c,
            LH_Recruiter_Name__c,
            LH_Email_Recruiter__c,
            LH_Phone_Recruiter__c,
            LH_Intro__c,
            LH_Who_are_we_looking_for__c,
            LH_Learn_Develop__c,
            LH_Call_to_Action__c,
            LH_Meta_title_description__c,
            LH_Head_Intro__c,
            LH_Head_ho_are_we_looking_for__c,
            LH_Head_Job_Description__c,
            LH_Head_Employment_Conditions__c,
            LH_Head_Learn_Develop__c,
            LH_Head_Company_Description__c,
            LH_Lead_call_to_action__c,
            LH_Meta_title__c,
            msf__Salary_Period__c,
            msf__Work_Country__c,
            msf__Work_Address_Post_Code__c,
            msf__Experience_Level__c,
            LastModifiedDate
        } = vacancy;

        // Disable eslint for the description because we need to use the fields in this order, not alphabetical
        /* eslint-disable */
        // Stringify the description object to keep compatibility with zzp recruitee vacancies
        const description = JSON.stringify({
            intro: LH_Intro__c ?? null,
            whoAreWe: LH_Who_are_we_looking_for__c ?? null,
            jobDescription: msf__Job_Description__c ?? null,
            employmentConditions: msf__Employment_Conditions__c ?? null,
            learnDevelop: LH_Learn_Develop__c ?? null,
            companyDescription: msf__Company_Description__c ?? null,
            callToAction: LH_Call_to_Action__c ?? null
        });

        const descriptionHeadings = {
            intro: LH_Head_Intro__c ?? null,
            whoAreWe: LH_Head_ho_are_we_looking_for__c ?? null,
            jobDescription: LH_Head_Job_Description__c ?? null,
            employmentConditions: LH_Head_Employment_Conditions__c ?? null,
            learnDevelop: LH_Head_Learn_Develop__c ?? null,
            companyDescription: LH_Head_Company_Description__c ?? null,
            callToAction: LH_Lead_call_to_action__c ?? null
        };
        /* eslint-enable */

        return {
            city:
                replaceNonRegionalLocation(vacancy.msf__Work_Address_City__c) ??
                null,
            client: { town: vacancy.msf__Work_Address_City__c ?? null },
            country: msf__Work_Country__c ?? null,
            created_at: vacancy.CreatedDate ?? null,
            department: vacancy.msf__Professional_Field__c ?? null,
            description: description,
            descriptionHeadings,
            education_code: vacancy.msf__Education_Level__c ?? null,
            employmentType: vacancy.msf__Job_Type__c ?? null,
            experienceLevel: msf__Experience_Level__c ?? null,
            id: vacancy.Id ?? null,
            isZZP: false,
            lastModified: LastModifiedDate ?? null,
            max_hours: vacancy.msf__Hours_Per_Week__c ?? null,
            metaDescription: LH_Meta_title_description__c ?? null,
            metaTitle: LH_Meta_title__c ?? null,
            min_hours: vacancy.LH_Min_hours_per_week__c ?? null,
            niveau: vacancy.msf__Education_Level__c ?? null,
            postcode: msf__Work_Address_Post_Code__c ?? null,
            recruiter: {
                email: LH_Email_Recruiter__c ?? null,
                name: LH_Recruiter_Name__c ?? null,
                phone: LH_Phone_Recruiter__c ?? null
            },
            salary: {
                max: vacancy.msf__Salary_to__c?.toString() ?? null,
                min: vacancy.msf__Salary_from__c?.toString() ?? null,
                period: msf__Salary_Period__c ?? null
            },
            slug: vacancy.LH_Slug__c ?? "",
            title: vacancy.Name?.toString() ?? ""
        };
    });
}

// -------------------------------------
// Exported functions
// -------------------------------------

export async function retrieveMysolutionVacancies(
    secondTry = false
): Promise<Array<VacancyProps> | null> {
    const api = process.env.MYSOLUTION_API;
    const vacancyEndpoint = process.env.MYSOLUTION_VACANCIES_ENDPOINT;

    if (!api || !vacancyEndpoint) {
        console.error(
            "Secret(s) not present in .env while retrieving mysolution vacancies"
        );
        console.log({ api, vacancyEndpoint });
        return null;
    }

    // Get token from cache
    const mysolutionToken = await getMysolutionToken();

    if (!mysolutionToken) {
        console.error("No token present while retrieving mysolution vacancies");
        return null;
    }

    // Get vacancies from mysolution using token
    try {
        const results:
            | Array<MysolutionVacancy>
            | MysolutionInvalidTokenResponse = await fetch(
            `${api}${vacancyEndpoint}`,
            {
                headers: {
                    Authorization: `Bearer ${mysolutionToken}`
                }
            }
        ).then(res => res.json());

        // If we get an array back, map it to the correct props and return the vacancies
        if (Array.isArray(results)) {
            return mapMysolutionVacanciesToVacancyProps(results);
        }

        // If we get an error back about the session being invalid
        // check if we are already retrying with a new token
        if (secondTry && results.errorCode) {
            console.error(
                "Api returns error but retry with new token did not work."
            );
            return null;
        }

        // If it's the first time, try again with a non-cached token
        await getMysolutionToken(true);

        // Retry and add a flag to prevent infinite loops
        return await retrieveMysolutionVacancies(true);
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function retrieveZzpVacancies(): Promise<Array<VacancyProps>> {
    const cacheKey = "zzp-vacancies";
    const cachedData = memoryCache.get(cacheKey);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    if (cachedData) return cachedData;

    const results = await fetch(process.env.INTERLEAD_API || "", {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        headers: {
            Authorization: process.env.INTERLEAD_TOKEN,
            "Content-Type": "application/json"
        },
        method: "GET"
    })
        .then(response => response.json())
        // eslint-disable-next-line
        .catch(err => console.log(err));

    // We have to transform the id to a string to keep it consistent with the mysolution vacancies
    const transformIdVacancies = results.map((vacancy: VacancyProps) => ({
        ...vacancy,
        id: vacancy.id?.toString(),
        isZZP: true
    }));

    memoryCache.put(cacheKey, transformIdVacancies, 1 * 1000 * 60);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return transformIdVacancies;
}

export async function retrieveFilterDepartments(): Promise<
    Array<StoryblokDataSource>
> {
    const cacheKey = "filter-departments";
    const cachedData = memoryCache.get(cacheKey);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    if (cachedData) return cachedData;

    const storyBlokApi = getStoryblokApi();

    try {
        const results = await storyBlokApi.get(
            "cdn/datasource_entries?datasource=departments"
        );

        const departments: Array<StoryblokDataSource> =
            results.data.datasource_entries;

        memoryCache.put(cacheKey, departments, 1 * 1000 * 60);

        return departments;
    } catch (error) {
        return [
            {
                id: 4672400,
                name: "Studenten",
                value: "Studenten"
            },
            {
                id: 4672396,
                name: "Apotheker",
                value: "Apotheker"
            },
            {
                id: 4672397,
                name: "Apothekersassistent",
                value: "Apothekersassistent"
            },
            {
                id: 4672398,
                name: "Artsen",
                value: "Artsen"
            },
            {
                id: 4672399,
                name: "Basisartsen",
                value: "Basisartsen"
            },
            {
                id: 4672401,
                name: "Verpleegkundigen",
                value: "Verpleegkundigen"
            },
            {
                id: 4672431,
                name: "Doktersassistent",
                value: "Doktersassistent"
            },
            {
                id: 4806996,
                name: "Kantoor",
                value: "Kantoor"
            }
        ];
    }
}

export async function retrieveEmploymentTypes(): Promise<
    Array<StoryblokDataSource>
> {
    const cacheKey = "employment-types";
    const cachedData = memoryCache.get(cacheKey);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    if (cachedData) return cachedData;

    const storyBlokApi = getStoryblokApi();

    try {
        const results = await storyBlokApi.get(
            "cdn/datasource_entries?datasource=employment-types"
        );

        const employmentTypes: Array<StoryblokDataSource> =
            results.data.datasource_entries;

        memoryCache.put(cacheKey, employmentTypes, 1 * 1000 * 60);

        return employmentTypes;
    } catch (error) {
        return [
            {
                id: 10264577,
                name: "Vast",
                value: "Secondment"
            },
            {
                id: 10264578,
                name: "Uitzenden",
                value: "Temporary"
            }
        ];
    }
}

export function getSingularDepartment(department: string) {
    switch (department) {
        case "Basisartsen":
            return "Basisarts";
        case "Verpleegkundigen":
            return "Verpleegkundige";

        default:
            return department;
    }
}
